import React from "react";

const PrevArrowSquareIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.704 12.796L14.204 20.296C14.416 20.508 14.702 20.626 15.002 20.626C15.302 20.626 15.588 20.508 15.8 20.296C16.012 20.084 16.13 19.798 16.13 19.498C16.13 19.198 16.012 18.912 15.8 18.7L9.1 12L15.8 5.3C15.9 5.2 15.984 5.084 16.03 4.956C16.076 4.828 16.1 4.698 16.1 4.566C16.1 4.434 16.076 4.304 16.03 4.176C15.984 4.048 15.9 3.932 15.8 3.832C15.7 3.732 15.584 3.648 15.456 3.602C15.328 3.556 15.198 3.532 15.066 3.532C14.934 3.532 14.804 3.556 14.676 3.602C14.548 3.648 14.432 3.732 14.332 3.832L6.832 11.332C6.732 11.432 6.648 11.548 6.602 11.676C6.556 11.804 6.532 11.934 6.532 12.066C6.532 12.198 6.556 12.328 6.602 12.456C6.648 12.584 6.732 12.7 6.832 12.8Z"
        fill="#E8E5FF"
      />
    </svg>
  );
};

export default PrevArrowSquareIcon;
