import React from "react";

const NextArrowSquareIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.296 12.796L9.796 20.296C9.584 20.508 9.298 20.626 8.998 20.626C8.698 20.626 8.412 20.508 8.2 20.296C7.988 20.084 7.87 19.798 7.87 19.498C7.87 19.198 7.988 18.912 8.2 18.7L14.9 12L8.2 5.3C8.1 5.2 8.016 5.084 7.97 4.956C7.924 4.828 7.9 4.698 7.9 4.566C7.9 4.434 7.924 4.304 7.97 4.176C8.016 4.048 8.1 3.932 8.2 3.832C8.3 3.732 8.416 3.648 8.544 3.602C8.672 3.556 8.802 3.532 8.934 3.532C9.066 3.532 9.196 3.556 9.324 3.602C9.452 3.648 9.568 3.732 9.668 3.832L17.168 11.332C17.268 11.432 17.352 11.548 17.398 11.676C17.444 11.804 17.468 11.934 17.468 12.066C17.468 12.198 17.444 12.328 17.398 12.456C17.352 12.584 17.268 12.7 17.168 12.8Z"
        fill="#E8E5FF"
      />
    </svg>
  );
};

export default NextArrowSquareIcon;
