import Link from "next/link";
import Image from "next/image";
import st from "../styles/components/SlotsList.module.scss";
import { usePathname } from "next/navigation";
import { useMemo } from "react";

export default function ProviderLink({
  item,
  scrollToTop,
  providerPage,
  isSlotsPage = false,
}) {
  const pathname = usePathname();

  const href = isSlotsPage ? pathname : `/casino/slots`;

  return (
    <div onClick={scrollToTop}>
      <Link
        shallow
        className={st["provider-link-content"]}
        prefetch={false}
        href={
          providerPage
            ? {
                pathname: `/casino/providers/${item.code}`,
              }
            : {
                pathname: href,
                query: { provider: item.name },
              }
        }
      >
        <div className={st["img-container"]}>
          {item.logo ? (
            <Image
              alt={item.name}
              src={item.logo}
              fill
              sizes="120px"
              style={{
                objectFit: "contain",
              }}
            />
          ) : null}
        </div>
      </Link>
    </div>
  );
}
