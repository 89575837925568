import { useState, useEffect } from "react";
import st from "../styles/components/SlotsList.module.scss";
import InputItem from "../items/InputItem";
import InputDropDown from "../items/InputDropDown";
import ProviderLink from "../items/ProviderLink";
import useMobile from "../hooks/useMobile";
import { EVENTS, usePubSub } from "@lib/pubsub";
import PrevArrowSquareIcon from "@assets/icons/general/PrevArrowSquareIcon";
import NextArrowSquareIcon from "@assets/icons/general/NextArrowSquareIcon";

export default function ProviderList({
  heading,
  searchSection = false,
  default_sort_by = "popular",
  carousel = false,
  onScroll,
  grouping,
  isSlotsPage = false,
}) {
  const [search, setSearch] = useState("");
  const [provider, setProvider] = useState("");
  const [sortBy, setSortBy] = useState(default_sort_by);
  const [resultCount, setResultCount] = useState(6);
  const slotsList = usePubSub(EVENTS.PROVIDERS, []);

  const isMobile = useMobile({ breakPointThree: 600 });

  const [startIndex, setStartIndex] = useState(0);
  const resultsPerPage = resultCount;

  const handleNext = () => {
    if (!slotsList || slotsList.length === 0) return;
    const newIndex = startIndex + resultsPerPage;
    if (newIndex < slotsList.length) {
      setStartIndex(newIndex);
    } else {
      setStartIndex(0);
    }
  };

  const handlePrevious = () => {
    if (!slotsList || slotsList.length === 0) return;
    const newIndex = startIndex - resultsPerPage;
    if (newIndex >= 0) {
      setStartIndex(newIndex);
    } else {
      setStartIndex(slotsList.length - resultsPerPage);
    }
  };

  useEffect(() => {
    if (isMobile === "mobile-one") {
      setResultCount(6);
    } else if (isMobile === "mobile-two") {
      setResultCount(5);
    } else if (isMobile === "mobile-three") {
      setResultCount(4);
    } else if (isMobile === "mobile-four") {
      setResultCount(3);
    } else {
      setResultCount(8);
    }
  }, [isMobile]);

  const scrollToTop = () => {
    document?.querySelector("#__next")?.scrollTo(0, 0);
  };

  return (
    <section className={st["list-container"]}>
      {heading ? <span className={st["heading-item"]}>{heading}</span> : null}

      {searchSection ? (
        <div className={st["search-area"]}>
          <InputItem
            classType={["row", "search", "search-input"]}
            name="search"
            value={search}
            method={setSearch}
            placeholder={"Search"}
            icon={
              <svg
                width="20"
                height="20"
                viewBox="0 0 17 17"
                fill="#fff"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.0093 13.2719L10.4687 9.72812C11.1187 8.91562 11.5093 7.88125 11.5093 6.75625C11.5093 4.12812 9.38115 2 6.75928 2C4.13428 2 2.00928 4.13125 2.00928 6.75625C2.00928 9.38125 4.1374 11.5125 6.75928 11.5125C7.89053 11.5125 8.92803 11.1156 9.74365 10.4563L13.2812 14L14.0093 13.2719ZM3.75303 9.76875C2.9499 8.96563 2.50928 7.89688 2.50928 6.75938C2.50928 5.62188 2.95303 4.55313 3.75303 3.75C4.55303 2.94687 5.6249 2.5 6.75928 2.5C7.89365 2.5 8.9624 2.94375 9.76553 3.74688C10.5687 4.55 11.0093 5.61875 11.0093 6.75625C11.0093 7.89375 10.5655 8.9625 9.76553 9.76562C8.9624 10.5688 7.89365 11.0125 6.75928 11.0125C5.6249 11.0156 4.55615 10.5719 3.75303 9.76875Z"
                  fillOpacity="0.75"
                />
              </svg>
            }
          />

          <div className={st["filter-area"]}>
            <InputDropDown
              selectedOption={provider}
              name={"provider"}
              placeholder={"Provider"}
              classType={["search-dropdown"]}
              onSelectOption={(event) => setProvider(event.target.value)}
              options={[{ value: "Bgaming", label: "Bgaming" }]}
            />
            <InputDropDown
              selectedOption={sortBy}
              name={"SortBy"}
              classType={["search-dropdown"]}
              placeholder={"Sort By"}
              onSelectOption={(event) => setSortBy(event.target.value)}
              options={[
                { value: "popular", label: "Popular" },
                { value: "amount-won", label: "Amount Won" },
                { value: "a-z", label: "A-Z" },
                { value: "z-a", label: "Z-A" },
              ]}
            />
          </div>
        </div>
      ) : null}

      <div className={st["next-prev-container"]}>
        <div
          className={`${st["arrow-icon"]} ${startIndex === 0 ? st["deactive"] : ""}`}
          onClick={startIndex === 0 ? null : handlePrevious}
        >
          <PrevArrowSquareIcon />
        </div>
        <div
          className={`${st["arrow-icon"]} ${startIndex + resultsPerPage >= (slotsList?.length || 0) ? st["deactive"] : ""}`}
          onClick={
            startIndex + resultsPerPage >= (slotsList?.length || 0)
              ? null
              : handleNext
          }
        >
          <NextArrowSquareIcon />
        </div>
      </div>
      <div className={`${st["slots-list"]} ${st[isMobile]}`}>
        {slotsList && slotsList.length > 0
          ? !carousel
            ? slotsList
                .sort((a, b) => a.sort - b.sort)
                .map((item, i) => {
                  return (
                    <ProviderLink
                      key={i + "-" + item.id}
                      item={item}
                      scrollToTop={onScroll ?? scrollToTop}
                      grouping={grouping}
                    />
                  );
                })
            : slotsList
                .slice(startIndex, startIndex + resultsPerPage)
                .sort((a, b) => a.sort - b.sort)
                .map((item, i) => {
                  return (
                    <ProviderLink
                      key={i + "-" + item.id}
                      item={item}
                      scrollToTop={onScroll ?? scrollToTop}
                      grouping={grouping}
                      isSlotsPage={isSlotsPage}
                    />
                  );
                })
          : null}
      </div>
    </section>
  );
}
